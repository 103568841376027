import { template as template_4be70a018ccc4a65abb4ce5ee5a38a98 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4be70a018ccc4a65abb4ce5ee5a38a98(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
